<div
  [ngClass]="{
    'card-container': currentStep <= totalSteps,
    'card-container-flex': currentStep > totalSteps
  }"
>
  <div class="it-text-centered">
    <div>
      <span [ngSwitch]="currentStep">
        <div class="row">
          <div class="col-12">
            <div>
              <ng-container *ngSwitchCase="1">
                <div class="p-title">
                  <h3 class="title">
                    {{
                      "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.TITLE" | translate
                    }}
                  </h3>
                  <div class="subtitle">
                    {{
                      "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE_ROW_1"
                        | translate
                    }}
                    <br/>

                    <a
                      [href]="
                        'WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE_EMAIL_HREF'
                          | translate
                      "
                      >{{
                        "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE_EMAIL"
                          | translate
                      }}</a
                    >

                    <span style="margin-left:16px;">
                      {{
                        "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE_ROW_3"
                          | translate
                      }}

                      <span style="font-weight: bold;">
                        {{
                        "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE_ROW_3_BOLD"
                          | translate
                      }}
                      </span>
                    </span>
  
                    <br/>

                    <a
                      [href]="
                        'WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE2_EMAIL_HREF'
                          | translate
                      "
                      >{{
                        "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE2_EMAIL"
                          | translate
                      }}</a
                    >

                    <span style="margin-left:16px;">
                      {{
                        "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE_ROW_10"
                          | translate
                      }}  
                      <span style="font-weight: bold;">
                        {{
                          "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE_ROW_10_BOLD"
                            | translate
                        }}  
                      </span>
                    </span>
                    
                    <ul style="margin-top: 0.625em">
                      <li>
                        {{
                          "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.LIST_ITEM_1"
                            | translate
                        }}
                      </li>
                      <li>
                        {{
                          "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.LIST_ITEM_2"
                            | translate
                        }}
                      </li>
                      <li>
                        {{
                          "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.LIST_ITEM_3"
                            | translate
                        }}
                      </li>
                    </ul>
                    <!-- <br /> -->
                    {{
                      "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE_ROW_4"
                        | translate
                    }}
                    <br />
                    <b>
                      {{
                        "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE_ROW_5_BOLD"
                          | translate
                      }}</b
                    >
                    {{
                      "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE_ROW_5"
                        | translate
                    }}
                    <b
                      >{{
                        "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE_ROW_5_BOLD_1"
                          | translate
                      }}
                    </b>
                    {{
                      "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE_ROW_6"
                        | translate
                    }}
                    <BR/><BR/>
                    <b>
                      {{
                        "WITHDRAWAL_MEDICAL_RECORDS.STEPS.FIRST.SUBTITLE_ROW_9"
                          | translate
                      }}
                    </b>
                    
                    <ul>
                      <li *ngFor="let pdf of pdfRitiroCartelle">
                        <a
                          [href]="
                            'assets/pdf-ritirocartelle/' + pdf.type + '.pdf'
                          "
                          target="_blank"
                        >
                          {{ pdf.name }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="p-subtitle">
                  <app-place-departments-card
                    *ngFor="let element of dettagliRitiro"
                    [puntoRitiro]="element"
                    [isSelected]="
                      isSelectedPuntoRitiro(element.Files[0].IdRitiro)
                    "
                    (idRitiro)="IdRitiro = $event"
                  >
                  </app-place-departments-card>
                </div>

                <div class="d-flex justify-content-center p-2-side">
                  <button
                    (click)="handleBack()"
                    type="button"
                    class="btn btn-outline-primary m-1"
                  >
                    {{ "BUTTON.CANCEL" | translate }}
                  </button>
                  <button
                    type="button"
                    [disabled]="!IdRitiro"
                    (click)="handleForward()"
                    class="btn btn-primary m-1 p-5-side"
                  >
                    {{ "BUTTON.CONTINUE" | translate }}
                  </button>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="2">
                <div class="p-title">
                  <h3 class="title">
                    {{
                      "WITHDRAWAL_MEDICAL_RECORDS.STEPS.SECOND.TITLE_1"
                        | translate
                    }}
                  </h3>
                  <p class="subtitle">
                    {{
                      "WITHDRAWAL_MEDICAL_RECORDS.STEPS.SECOND.SUBTITLE"
                        | translate
                    }}
                  </p>
                </div>

                <form
                  [formGroup]="personalDataForm"
                  class="p-form"
                  autocomplete="off"
                >
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <input
                          formControlName="name"
                          type="text"
                          class="form-control"
                          id="name"
                        />
                        <label class="active" for="name">{{
                          "WITHDRAWAL_MEDICAL_RECORDS.PERSONAL_DATA.NAME"
                            | translate
                        }}</label>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <input
                          formControlName="birthdate"
                          type="date"
                          max="{{ today }}"
                          class="form-control"
                          id="birthdate"
                        />
                        <label class="active" for="birthdate">{{
                          "WITHDRAWAL_MEDICAL_RECORDS.PERSONAL_DATA.BIRTH_DATE"
                            | translate
                        }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <input
                          formControlName="reparto"
                          type="text"
                          class="form-control"
                          id="reparto"
                        />
                        <label class="active" for="reparto">{{
                          "WITHDRAWAL_MEDICAL_RECORDS.PERSONAL_DATA.DEPARTMENT_PLACE"
                            | translate
                        }}</label>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <input
                          formControlName="recovery_date"
                          type="date"
                          class="form-control"
                          id="recovery_date"
                          max="{{ today }}"
                        />
                        <label class="active" for="recovery_date">{{
                          "WITHDRAWAL_MEDICAL_RECORDS.PERSONAL_DATA.RECOVER_DATE"
                            | translate
                        }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="width: 50%">
                    <div class="col">
                      <div class="form-group">
                        <input
                          formControlName="email"
                          type="email"
                          class="form-control"
                          id="email"
                        />
                        <label class="active" for="email">{{
                          "WITHDRAWAL_MEDICAL_RECORDS.PERSONAL_DATA.EMAIL"
                            | translate
                        }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-center p-2-side">
                    <button
                      (click)="handleBack()"
                      type="button"
                      class="btn btn-outline-primary m-1"
                    >
                      {{ "BUTTON.BACK" | translate }}
                    </button>
                    <button
                      (click)="handleForward()"
                      type="submit"
                      [disabled]="personalDataForm.invalid"
                      class="btn btn-primary m-1 p-5-side"
                    >
                      {{ "BUTTON.CONTINUE" | translate }}
                    </button>
                  </div>
                </form>
              </ng-container>

              <ng-container *ngSwitchCase="3">
                <div class="p-title">
                  <h3 class="title">
                    {{
                      "WITHDRAWAL_MEDICAL_RECORDS.STEPS.THIRD.TITLE" | translate
                    }}
                  </h3>
                </div>
                <div class="rectangle">
                  <div class="it-text-centered">
                    <div>
                      <div>
                        <h3 class="title text-align">
                          {{
                            "WITHDRAWAL_MEDICAL_RECORDS.STEPS.THIRD.TITLE_1"
                              | translate
                          }}
                        </h3>
                        <p class="subtitle text-align">
                          {{
                            "WITHDRAWAL_MEDICAL_RECORDS.STEPS.THIRD.SUBTITLE"
                              | translate
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <file-upload
                    [buttonText]="
                      'WITHDRAWAL_MEDICAL_RECORDS.STEPS.THIRD.UPLOAD_BUTTON'
                    "
                    (isUploaded)="isUploadedFile = $event"
                    (imgUploaded)="imgUploaded = $event"
                  >
                  </file-upload>
                </div>

                <div class="d-flex justify-content-center p-2-side">
                  <button
                    (click)="handleBack()"
                    type="button"
                    class="btn btn-outline-primary m-1"
                  >
                    {{ "BUTTON.BACK" | translate }}
                  </button>
                  <button
                    [disabled]="!isUploadedFile"
                    type="button"
                    (click)="handleForward()"
                    class="btn btn-primary m-1 p-5-side"
                  >
                    {{
                      "WITHDRAWAL_MEDICAL_RECORDS.STEPS.THIRD.WITHDRAWAL_BUTTON"
                        | translate
                    }}
                  </button>
                </div>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <div class="container">
                  <div class="it-text-centered p-2-side">
                    <h1 class="title p-2-side">
                      {{
                        "WITHDRAWAL_MEDICAL_RECORDS.STEPS.SUCCESS.TITLE"
                          | translate
                      }}
                    </h1>
                    <p class="subtitle p-2-side">
                      {{
                        "WITHDRAWAL_MEDICAL_RECORDS.STEPS.SUCCESS.SUBTITLE"
                          | translate
                      }}
                    </p>

                    <div class="d-flex justify-content-center">
                      <button
                        type="button"
                        (click)="backToHome()"
                        class="btn btn-primary"
                      >
                        {{ "BUTTON.BACK_HOME" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </span>
    </div>
  </div>
</div>